import {
  Accessor,
  createResource,
  For,
  JSX,
  Match,
  Setter,
  Show,
  Switch,
} from "solid-js";
import { ThreeDotLoader } from "~/widgets/button";
import { Divider, DividerV2 } from "~/widgets/divider";
import { CloseModalButton, useModal } from "~/components/modal";
import { Spacer } from "~/widgets/spacer";
import {
  CoinsTransactionData,
  CoinsTransactionsResponse,
} from "~/data/transaction";
import { getCoinTransactions } from "~/server/apis/client_apis";
import { coinIcon } from "~/assets/assets";
import { toIndianNumber } from "~/utils/number";
import { classNames } from "~/utils/etc";
import { formatDateShort, formateDateShortWithTime } from "~/utils/date";
import { PhosphorIcon } from "~/widgets/icons";
import { createAutoAnimate } from "@formkit/auto-animate/solid";
import { EmptyState } from "~/widgets/empty_state";
import { RedeemCodeModal } from "./redeem_code_modal";

export type CoinsTransactionsModalProps = {
  balance: number;
  userName: string;
  hubbleCodeMaxLength?: number;
  setRefreshCoins: Setter<boolean>;
  enableRedeemCode: boolean;
  title?: string;
  subtitle?: string;
  headers?: Record<string, string>;
  onError?: (error: any) => void;
  showExpiresOn?: boolean;
};

export function CoinsTransactionsModal(props: CoinsTransactionsModalProps) {
  const [response, mutateResponse] = createResource<CoinsTransactionsResponse>(
    async () => {
      let response: CoinsTransactionsResponse;
      try {
        response = await getCoinTransactions(props.headers);
        return response;
      } catch (error) {
        props.onError?.(error);
        throw error;
      }
    }
  );
  const [parent, setEnabled] = createAutoAnimate();

  const { openModal, closeModal, themeMode } = useModal()!;

  function openRedeemCodeModal() {
    openModal(() => {
      return (
        <RedeemCodeModal
          setRefreshCoins={props.setRefreshCoins}
          onClose={() => {
            openModal(() => {
              return (
                <CoinsTransactionsModal
                  setRefreshCoins={props.setRefreshCoins}
                  balance={props.balance}
                  userName={props.userName}
                  hubbleCodeMaxLength={props.hubbleCodeMaxLength}
                  enableRedeemCode={props.enableRedeemCode}
                />
              );
            });
          }}
          userName={props.userName}
          maxCodeLength={props.hubbleCodeMaxLength}
        />
      );
    }, "md:w-[416px] h-fit");
  }

  function getParentContainerGradient(): Record<string, string> {
    if (themeMode() === "dark") {
      return {
        background: "linear-gradient(180deg, #1E1504 0%, #000 100%)",
      };
    }
    return {};
  }

  function getBalanceContainerGradient() {
    if (themeMode() === "light") {
      return {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 59.5%), linear-gradient(77deg, #EEE2C9 3.77%, #D1D6DA 81.54%)",
      };
    }
    return {};
  }

  return (
    <div
      class="my-auto flex h-fit w-full flex-col rounded-t-2xl border border-neutral-200 font-[Inter]   dark:border-basePrimaryDark  lg:rounded-b-2xl"
      style={{
        ...getParentContainerGradient(),
      }}
    >
      <div
        class="relative flex h-fit flex-col items-start rounded-t-[15px]"
        style={{ ...getBalanceContainerGradient() }}
      >
        <div class="absolute right-4 top-6 lg:right-2 lg:top-2">
          <CloseModalButton />
        </div>
        <Spacer height={24} />

        <div class="flex w-full flex-col items-center  justify-center  ">
          <div class="text-h4 text-textDark dark:text-textWhite  lg:text-h2 ">
            {`${props.title || "Hubble rewards"}`}
          </div>
          <div class="px-4 pt-1 text-center text-medium text-textDark dark:text-textNormal lg:text-normal">
            {`${props.subtitle || "Use & save on top of Instant discounts"}`}
          </div>
        </div>

        <Spacer height={12} />
        <div
          class="mx-4 flex flex-col items-center justify-center self-stretch rounded-3xl border border-baseTertiaryDark bg-baseTertiaryLight  px-4 py-5 dark:border-basePrimaryDark dark:bg-[#010101] lg:mx-6"
          style={{
            "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
          }}
        >
          <span class="text-f12w700 text-textNormal">BALANCE</span>
          <Spacer height={8} />
          <div class="flex flex-row items-center">
            <img src={coinIcon} class="h-8 w-8"></img>
            <Spacer width={4} />
            <span class="text-h2 text-[#D39721]">
              {toIndianNumber(props.balance)}
            </span>
          </div>
          <Show when={props.enableRedeemCode}>
            <div class="w-full py-3">
              <Divider bgColor="#EDEEF1" />
            </div>
            <div class="text-medium text-darkBg">
              Have a code?{" "}
              <span
                class="cursor-pointer text-mediumSemiBold underline"
                onClick={() => {
                  closeModal();
                  openRedeemCodeModal();
                }}
              >
                Redeem
              </span>
            </div>
          </Show>
        </div>
        <Spacer height={20} />
      </div>
      <div
        ref={parent}
        class="noScrollbar flex h-[320px] flex-col items-center justify-start gap-2  self-stretch overflow-y-scroll px-4 text-textDark  lg:px-6 "
      >
        <Switch>
          <Match when={response.state === "ready"}>
            <Show
              when={response()!.data.length > 0}
              fallback={
                <EmptyState
                  primaryText="No transactions yet"
                  secondaryText="Your coin transactions will appear here."
                  classList="my-0 py-6"
                />
              }
            >
              <For each={response()!.data}>
                {(transaction, index) => {
                  return [
                    transactionLog({
                      transaction,
                      index,
                      showExpiresOn: props.showExpiresOn ?? true,
                    }),
                    <Show
                      when={index() < response()!.data.length - 1}
                      fallback={<div class="h-6">&nbsp</div>}
                    >
                      <DividerV2 class="bg-baseTertiaryMedium dark:bg-basePrimaryDark " />
                    </Show>,
                  ];
                }}
              </For>
            </Show>
          </Match>
          <Match when={response.state === "pending"}>
            <div class="flex h-full w-full flex-col items-center justify-center">
              <ThreeDotLoader color="#000" />
            </div>
          </Match>
          <Match when={response.state === "errored"}>
            <div class="dark:text-textWhite">{response.error.message}</div>
          </Match>
        </Switch>
      </div>
    </div>
  );

  function transactionLog(props: {
    transaction: CoinsTransactionData;
    index: Accessor<number>;
    showExpiresOn: boolean;
  }) {
    const isCredit = props.transaction.transactionType === "CREDIT";
    return (
      <div
        onClick={() => {
          if (isCredit) {
          }
        }}
        class="flex w-full flex-row items-center px-1 py-4 "
      >
        <div
          class={classNames(
            "flex h-10 w-10 items-center justify-center rounded-[10px] bg-opacity-10 p-[3px] ",
            isCredit ? "bg-successDark" : "bg-errorDark"
          )}
        >
          <PhosphorIcon
            name={isCredit ? "arrow-up-right" : "arrow-down-left"}
            class={classNames(isCredit ? "text-successDark" : "text-errorDark")}
            fontSize={24}
            size="bold"
          />
        </div>
        <Spacer width={12} />
        <div class="flex w-full flex-col ">
          <span class="grow text-bold dark:text-textWhite">
            {props.transaction.remarks}
          </span>
          <Spacer height={5} />
          <div class="flex w-full flex-row items-center justify-start">
            <div class="grow text-medium text-textNormal">
              {getDateText({
                transaction: props.transaction,
                showExpiresOn: props.showExpiresOn,
              })}
            </div>
            <div
              class={classNames(
                "flex flex-row items-center text-mediumBold",
                isCredit ? "text-successDark" : "text-errorDark"
              )}
            >
              {isCredit ? "+" : "-"}
              <img src={coinIcon} class="mx-[2px] h-5 w-5"></img>
              {toIndianNumber(props.transaction.amount)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getDateText(props: {
    transaction: CoinsTransactionData;
    showExpiresOn: boolean;
  }) {
    const expiresOn = props.transaction.expiresOn;
    const dateTime = props.transaction.dateTime;
    if (expiresOn != null && expiresOn != "" && props.showExpiresOn) {
      return `${formatDateShort(new Date(dateTime))} · Expires ${formatDateShort(new Date(expiresOn))}`;
    }
    return `${formateDateShortWithTime(new Date(dateTime))}`;
  }
}
