import { Cookie, Mode, QueryParam } from "~/types";
import { Params } from "@solidjs/router";
import { config } from "~/data/config";
import { getCookie, setCookie } from "~/utils/client_cookie";
import { getPartnerSecrets } from "~/data/partner_secrets";
import { visit } from "unist-util-visit";
import { Product } from "~/server/types/search";
import { showSnackBar } from "~/shared_states/snackbar";

export const getModeFromCookie = (): Mode | undefined => {
  return getCookie(Cookie.Mode) as Mode | undefined;
};

export const getClientIdFromCookie = (): string | undefined => {
  return getCookie(Cookie.ClientId) as string | undefined;
};

export const getModeFromQueryParamsAndCookies = (
  queryParams?: Partial<Params>
): Mode => {
  let clientId: string | undefined | null;
  let clientSecret: string | undefined | null;
  let token: string | undefined | null;

  if (getModeFromCookie() === "rnr") {
    return "rnr";
  }

  if (!queryParams) {
    const searchParams = new URLSearchParams(window.location.search);
    clientId = searchParams.get(QueryParam.ClientId);
    clientSecret = searchParams.get(QueryParam.ClientSecret);
    token = searchParams.get(QueryParam.Token);
  } else {
    clientId = queryParams.clientId;
    clientSecret = queryParams.clientSecret;
    token = queryParams.token;
  }

  if (clientId && clientSecret && token) {
    return "sdk";
  }

  return "hubble";
};

export const getCategorySlugFromCategoryName = (
  categoryName: string
): string => {
  return categoryName.toLowerCase().replaceAll("_", "-") + "-gift-cards";
};

export const getBrandKeyFromSlug = (slug: string): string => {
  return slug
    .replace(/-gift-card(-.*$)?/, "")
    .replace("amazon-shopping", "amazon");
};

export const newRequestId = () => {
  return Math.random().toString(36).slice(2);
};

export const getCoinLabel = (
  clientId: string,
  options: {
    capitalize: boolean;
    pluralize: boolean;
  }
) => {
  let result = "";

  if (clientId.startsWith(config.clientIdPrefix.visitHealth)) {
    result = options.pluralize ? "fitcoins" : "fitcoin";
  } else {
    result = options.pluralize ? "coins" : "coin";
  }

  if (options.capitalize) {
    result = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return result;
};

export const getCoinLabelCapitalizedPluralized = (clientId: string) => {
  return getCoinLabel(clientId, {
    capitalize: true,
    pluralize: true,
  });
};

export const getCoinLabelCapitalized = (clientId: string) => {
  return getCoinLabel(clientId, {
    capitalize: true,
    pluralize: false,
  });
};

export const isHomeBackBtnEnabledFor = (clientId: string): boolean => {
  if (clientId.startsWith(config.clientIdPrefix.visitHealth)) {
    return false;
  }

  return true;
};

export const isCloseBtnEnabledFor = (clientId: string): boolean => {
  if (clientId.startsWith(config.clientIdPrefix.visitHealth)) {
    return false;
  }

  return true;
};

export const showReloadOnError = (clientId: string): boolean => {
  if (clientId.startsWith(config.clientIdPrefix.visitHealth)) {
    return true;
  }

  return false;
};

export const isClientIdFromRNR = (clientId: string): boolean => {
  const partnerData = getPartnerSecrets(config.env);
  const clientIds = Object.values(partnerData).map((secret) => secret.clientId);

  if (clientIds.find((cId) => cId === clientId)) {
    return true;
  }

  return false;
};

export const areMixpanelEventsEnabledFor = (clientId?: string): boolean => {
  if (clientId) {
    if (isClientIdFromRNR(clientId)) {
      return config.mixpanelEvents.enableForRnr;
    }
    return config.mixpanelEvents.enableForSdk;
  }

  return config.mixpanelEvents.enableForWebStore;
};

export function hasNonNullOrEmptyValue<T extends Record<string, any>>(
  obj: T
): boolean {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== "") {
        return true;
      }
    }
  }
  return false;
}

export function rehypeUppercaseATags() {
  // @ts-ignore
  return (tree) => {
    visit(tree, "element", (node) => {
      if (node.tagName === "a") {
        node.tagName = "A";
      }
    });
  };
}

export const getCategoryKeyFromSlug = (slug: string): string => {
  return slug
    .replace(/-gift-cards?$/, "")
    .replaceAll("-", "_")
    .toUpperCase();
};

export const getCategoryTitleFromSlug = (slug: string): string => {
  return slug
    .replace(/-gift-cards?$/, "")
    .replaceAll("-", " ")
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const getCategoryNameFromSlug = (text: string): string => {
  const prefix = "gift-card-for-";
  if (text.startsWith(prefix)) {
    return text.slice(prefix.length);
  }
  return text;
};

export const getHighestPercentOff = (list: Product[]): number => {
  return (list ?? [])
    .filter(
      (product) =>
        product.voucherProduct && product.voucherProduct.discountPercentage
    )
    .reduce((max, product) => {
      return Math.max(max, product.voucherProduct.discountPercentage);
    }, 0);
};

export const formatGroup = (occasion: string) => {
  return occasion.toLowerCase().replace(/ /g, "-").replace(/'/g, "");
};

export const copyText = async (params: {
  text: string;
  messageOnSuccess?: string;
  messageOnError?: string;
}) => {
  try {
    await navigator.clipboard.writeText(params.text);

    showSnackBar({
      message: params.messageOnSuccess ?? "Copied to clipboard",
      level: "success",
    });
  } catch (e) {
    showSnackBar({
      message: params.messageOnError ?? "Failed to copy text",
      level: "error",
    });
  }
};

export async function generateDeviceVerificationToken(): Promise<string> {
  const token = getCookie(Cookie.FpjsRequestId);
  if (token) {
    return token;
  }

  // @ts-ignore
  const { requestId } = await FingerPrint.run();
  setCookie({
    key: Cookie.FpjsRequestId,
    value: requestId,
    expiryInMinutes: 60,
  });
  return requestId;
}
