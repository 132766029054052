import { useNavigate, useSearchParams } from "@solidjs/router";
import {
  Accessor,
  Match,
  Setter,
  Switch,
  createEffect,
  createSignal,
  onMount,
  untrack,
} from "solid-js";
import { Show, getRequestEvent } from "solid-js/web";
import { AboutGiftCard } from "~/components/about_gift_card";
import { useModal } from "~/components/modal";
import { setAuth } from "~/data/auth";
import { config } from "~/data/config";
import { logIntoHubble } from "~/screens/hubble_otp_auth/hubble_otp_auth";
import { Product } from "~/server/types/search";
import { Cookie, Mode, Optional } from "~/types";
import { getCookie } from "~/utils/client_cookie";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";
import { zIndexes } from "~/z_indexes";
import { HeaderMenu } from "../header_menu";
import { Search, getSearchResults } from "../search/search";
import { webEventManager } from "~/data/events";
import { coinIcon, profileIcon } from "~/assets/assets";
import { BusinessMenuCta } from "../business_menu";
import { toIndianNumber } from "~/utils/number";
import { CoinsTransactionsModal } from "../coins_transactions_modal";
import { getCoinsSummary } from "~/server/apis/client_apis";
import { RedeemCodeModal } from "~/components/redeem_code_modal";

type HeaderProps = {
  headerImageUrl?: string;
  showActions: boolean;
  isLoggedIn: Accessor<boolean>;
  mode: Mode;
  coins?: Accessor<number>;
  showCoins?: Accessor<boolean>;
  setRefreshCoins?: Setter<boolean>;
  userName?: string;
};
export const [isFreshLogin, setIsFreshLogin] =
  createSignal<Optional<boolean>>(null);

export function Header(props: HeaderProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  let headerImageHref = config.paths.homePageUrl;
  let headerImageUrl = props.headerImageUrl;

  if (searchParams.clientId) {
    if (searchParams.headerImageUrl) {
      headerImageUrl = searchParams.headerImageUrl;
    }

    if (searchParams.headerImageHref) {
      headerImageHref = searchParams.headerImageHref;
    }
  }

  const navigate = useNavigate();
  const { setIsModalOn, updateModalContentGetter, openModal } = useModal()!;
  const [isSearchActive, setIsSearchActive] = createSignal(false);
  const [clearSearch, setClearSearch] = createSignal(false);
  const [showMenu, setShowMenu] = createSignal(false);
  const [allBrands, setAllBrands] = createSignal<Product[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = createSignal<boolean>(false);
  const [isBusinessDropdownOpen, setIsBusinessDropdownOpen] =
    createSignal<boolean>(false);
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      untrack(() => {
        setRefreshLatestBalance(false);
      });
    }
  });

  onMount(async () => {
    setAllBrands(
      await getSearchResults(
        "",
        { filterOutInactive: false },
        { pageNo: 0, limit: 30 }
      )
    );

    const sid = getCookie(Cookie.SessionId);
    if (sid) {
      setAuth(() => {
        return {
          sessionId: sid,
        } as any;
      });
    }

    const requestEvent = getRequestEvent();
    if (requestEvent?.locals.logoUrl) {
      headerImageUrl = requestEvent.locals.logoUrl;
    }
  });

  return (
    <div
      class="sticky top-0 w-full bg-white"
      style={{
        "z-index": zIndexes.header,
      }}
    >
      <div class="flex flex-col items-center ">
        <div class="ml-4 mr-4 flex w-full flex-row items-center justify-between px-4 py-3 lg:mx-4 lg:max-w-[1136px] lg:px-0 lg:py-4">
          <div
            class={`absolute left-0 right-0  h-screen w-full  bg-black transition-all duration-300 md:top-20 ${isSearchActive() ? "opacity-40" : "pointer-events-none opacity-0"} ${isSearchActive() ? "top-32" : "top-20"} ${isDropdownOpen() || isBusinessDropdownOpen() ? "opacity-40" : "pointer-events-none opacity-0"}`}
          />
          <div class="w-fit lg:w-[286px]">
            <HeaderLogo
              imageUrl={headerImageUrl}
              imageHref={headerImageHref}
              mode={props.mode}
            />
          </div>
          <Show when={props.showActions}>
            <>
              <div class="hidden md:flex">{buildSearchBar()}</div>
              <div class="flex flex-row items-center justify-end text-textDark lg:w-[286px]">
                <Show when={props.mode === "hubble" && !props.isLoggedIn()}>
                  <div class="hidden md:flex">
                    <BusinessMenuCta
                      setIsDropdownOpen={setIsBusinessDropdownOpen}
                      isDropdownOpen={isBusinessDropdownOpen}
                    />
                  </div>
                </Show>
                <Spacer width={16} />
                <Show when={props.isLoggedIn()}>
                  {buildCoinsPillOrBranding()}
                </Show>
                <Spacer width={16} />
                <Switch>
                  <Match when={!(isFreshLogin() ?? props.isLoggedIn)}>
                    <div class="hidden lg:inline-flex">
                      <LoginButton />
                    </div>
                  </Match>
                  <Match when={true}>{buildHeaderMenu()}</Match>
                </Switch>
                <div class="lg:hidden">
                  <Show when={!(isFreshLogin() ?? props.isLoggedIn)}>
                    {buildHeaderMenu()}
                  </Show>
                </div>
                <div
                  class="mr-3 md:hidden"
                  classList={{
                    "mr-2": props.mode === "rnr",
                  }}
                >
                  <div>{buildSearchIcon()}</div>
                </div>
                <div class="lg:hidden">{buildMenuButtonListIcon()}</div>
              </div>
            </>
          </Show>
        </div>
        <div class="flex w-full flex-row items-center justify-center md:hidden">
          <Show when={isSearchActive()}>{buildSearchBar()}</Show>
        </div>
        {/* // looks deprecated */}
        <Show when={showMenu()}>{buildMenu()}</Show>
      </div>
    </div>
  );

  function openCoinsTransactionsModal(event: MouseEvent) {
    event.stopPropagation();
    updateModalContentGetter(() => {
      return (
        <CoinsTransactionsModal
          balance={latestCoinsBalance() ?? props.coins?.() ?? 0}
          userName={props.userName ?? ""}
          setRefreshCoins={props.setRefreshCoins ?? setRefreshLatestBalance}
          enableRedeemCode={true}
          title={props.mode === "rnr" ? "Your coins" : undefined}
          subtitle={
            props.mode === "rnr"
              ? "Use it to get gift cards of your choice"
              : undefined
          }
        />
      );
    });

    setIsModalOn(true);
  }

  function CoinsPill() {
    return (
      <div>
        <div
          onClick={openCoinsTransactionsModal}
          class="hidden cursor-pointer items-center rounded-[50px] border-[0.5px] border-[#C0C0C0] bg-white  pl-2 pr-[6px] lg:flex"
          style={{
            "box-shadow": "0px 4px 6px 0px rgba(219, 221, 222, 0.2)",
          }}
        >
          <div class="flex items-center justify-center gap-1 py-2">
            <img src={coinIcon} alt="" class="h-5 w-5" />
            <div
              class={`text-f12w700 text-textDark ${props.mode != "rnr" ? "pr-1" : ""}`}
            >
              {toIndianNumber(latestCoinsBalance() ?? props.coins?.() ?? 0)}
            </div>
          </div>
          <Show when={props.mode === "rnr"}>
            <div
              class="flex cursor-pointer items-center rounded-e-2xl pl-1"
              onClick={() => {
                openModal(() => {
                  return (
                    <RedeemCodeModal
                      setRefreshCoins={
                        props.setRefreshCoins ?? setRefreshLatestBalance
                      }
                      userName={props.userName ?? ""}
                    />
                  );
                }, "md:w-[416px] h-fit");
              }}
            >
              <PhosphorIcon
                name="plus-circle"
                size="fill"
                fontSize={26}
                class="py-[4px]"
              />
            </div>
          </Show>
        </div>
        <div
          onClick={openCoinsTransactionsModal}
          class="flex h-8 cursor-pointer items-center rounded-[50px] border-[0.5px] border-[#C0C0C0] bg-white py-[7px] pl-[8.4px] pr-[6.4px] lg:hidden"
          style={{
            "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
          }}
        >
          <img src={coinIcon} alt="" class="h-4 w-4" />
          <Spacer width={2.4} />
          <div class="text-f12w700 text-textDark">
            {toIndianNumber(latestCoinsBalance() ?? props.coins?.() ?? 0)}
          </div>
          <Show when={props.mode === "rnr"}>
            <div
              class="flex cursor-pointer items-center rounded-e-2xl pl-1"
              onClick={() => {
                openModal(() => {
                  return (
                    <RedeemCodeModal
                      setRefreshCoins={
                        props.setRefreshCoins ?? setRefreshLatestBalance
                      }
                      userName={props.userName ?? ""}
                    />
                  );
                }, "md:w-[416px] h-fit");
              }}
            >
              <PhosphorIcon
                name="plus-circle"
                size="fill"
                fontSize={26}
                class="py-[4px]"
              />
            </div>
          </Show>
        </div>
      </div>
    );
  }

  function LoginButton() {
    return (
      <div
        class=" shadow flex h-10 w-fit cursor-pointer flex-row items-center justify-center rounded-[30px] border border-neutral-800 bg-white  px-[10px] "
        onClick={() => {
          logIntoHubble(() => {
            console.log(`setting sid cookie`);
          }, webEventManager);
        }}
      >
        <div class="px-[3.61px] text-center text-mediumBold text-textDark">
          Log in / Sign up
        </div>
      </div>
    );
  }

  function buildSearchBar() {
    return (
      <Search
        setIsSearchActive={setIsSearchActive}
        setClearSearch={setClearSearch}
        clearSearch={clearSearch}
        isSearchActive={isSearchActive}
        allBrands={allBrands}
      />
    );
  }

  function buildHeaderMenu() {
    return (
      <HeaderMenu
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        isLoggedIn={props.isLoggedIn()}
        showBusinessMenu={props.mode === "hubble"}
        mode={props.mode}
      />
    );
  }

  function buildMenuButtonListIcon() {
    return (
      <div
        class="cursor-pointer "
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen());
        }}
        tabIndex="0"
        onFocusOut={async () => {
          await new Promise((resolve) => setTimeout(resolve, 250));
          if (isDropdownOpen()) {
            setIsDropdownOpen(false);
          }
        }}
      >
        <Show
          when={isDropdownOpen()}
          fallback={<PhosphorIcon name="list" fontSize={26} size={"light"} />}
        >
          <PhosphorIcon name="x" fontSize={26} size={"light"} />
        </Show>
      </div>
    );
  }

  function buildCoinsPillOrBranding() {
    return (
      <div class="flex flex-row items-center gap-2">
        <Show when={props.mode == "rnr" && !(props.showCoins?.() ?? true)}>
          <button
            class={`flex h-8 w-fit flex-col items-center justify-center rounded-full bg-black px-3 py-3 text-f12Bold text-white lg:h-9 lg:text-mediumBold`}
            onClick={() => {
              openModal(() => {
                return (
                  <RedeemCodeModal
                    setRefreshCoins={
                      props.setRefreshCoins ?? setRefreshLatestBalance
                    }
                    userName={props.userName ?? ""}
                  />
                );
              }, "md:w-[416px] h-fit");
            }}
          >
            <div class="flex flex-row items-center justify-center">
              <PhosphorIcon
                name="plus"
                fontSize={14}
                class="pr-1"
                size={"bold"}
              />
              <div>Redeem code</div>
            </div>
          </button>
        </Show>
        <Switch>
          <Match when={props.showCoins?.() ?? true}>
            <CoinsPill />
          </Match>
          {/*<Match when={props.mode == "rnr"}>*/}
          {/*  <div class="z-50 flex h-full flex-col items-end ">*/}
          {/*    <span class="font-[Inter] text-[8.2px] font-semibold">*/}
          {/*      Powered by*/}
          {/*    </span>*/}
          {/*    <div class="h-[1px]" />*/}
          {/*    <HubbleFullSvg class="h-[11.7px]" />*/}
          {/*  </div>*/}
          {/*</Match>*/}
        </Switch>
      </div>
    );
  }

  function buildSearchIcon() {
    return (
      <div
        class="cursor-pointer "
        onClick={() => {
          setShowMenu(false);
          setIsSearchActive(!isSearchActive());
        }}
      >
        <PhosphorIcon name="magnifying-glass" fontSize={26} size={"light"} />
      </div>
    );
  }

  // looks deprecated
  function buildMenu() {
    return (
      <div class="inline-flex h-[102px]  w-full flex-col items-start justify-start gap-[13px] px-2">
        <div class="flex h-[88px] flex-col items-start justify-start">
          <Show
            when={!(isFreshLogin() ?? props.isLoggedIn)}
            fallback={
              <div
                class="inline-flex h-11 cursor-pointer items-center justify-start gap-1 self-stretch rounded-lg pl-1 pr-3"
                onClick={() => {
                  navigate("/profile", {
                    resolve: true,
                  });
                }}
              >
                <img src={profileIcon} class="h-4" alt="profile" />
                <div class="text-neutral-700z shrink grow basis-0 text-[15px] text-bolder leading-[4px]">
                  Profile
                </div>
              </div>
            }
          >
            <div
              class="inline-flex h-11 cursor-pointer items-center justify-start gap-1 self-stretch rounded-lg pl-1 pr-3"
              onClick={() => {
                logIntoHubble(() => {
                  console.log(`setting sid cookie`);
                }, webEventManager);
              }}
            >
              <img src={profileIcon} class="h-4" alt="login" />
              <div class="shrink grow basis-0 text-[15px] text-bolder leading-[4px] text-neutral-700">
                Log in / Sign up
              </div>
            </div>
          </Show>
          <div
            class="inline-flex h-11 cursor-pointer items-center justify-start gap-1 self-stretch rounded-lg bg-white pl-1 pr-3"
            onClick={() => {
              updateModalContentGetter(() => {
                return <AboutGiftCard />;
              });

              setIsModalOn(true);
            }}
          >
            <PhosphorIcon name="question" size="fill" />
            <div class="shrink grow basis-0 text-mediumBolder text-textDark">
              What’s a gift card?
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type HeaderLogoProps = {
  mode: Mode; // need this to determine image's height
  imageUrl?: string;
  imageHref: string;
};

function HeaderLogo(props: HeaderLogoProps) {
  const navigate = useNavigate();

  return (
    <img
      class="cursor-pointer "
      classList={{
        "h-[36px] lg:h-[48px]": props.mode === "rnr",
        "h-[24px] ": props.mode !== "rnr",
      }}
      src={
        props.imageUrl ??
        "https://s3.ap-south-1.amazonaws.com/assets.hubble/web-assets/hubble_full.svg"
      }
      alt="Hubble Logo"
      onClick={() => {
        navigate(props.imageHref);
      }}
    />
  );
}
