import { A } from "@solidjs/router";
import { Accessor, Match, Show, Switch, createSignal } from "solid-js";
import { placeholderCard } from "~/assets/assets";
import { HubbleEvent, webEventManager } from "~/data/events";
import { Product, VoucherProductStatus } from "~/server/types/search";
import HubbleImage from "~/widgets/hubble_image";
import { useModal } from "../modal";
import { DenominationDetailsModal } from "../procurement/denominations_details_modal";
import { useProcurement } from "~/context/procurement";

export function SearchResults(props: {
  result: Product[];
  noResultsFound: boolean;
  isSearchActive: Accessor<boolean>;
  onBrandClick: () => void;
  isFetchingResults: Accessor<boolean>;
  isProcurment?: boolean;
  openDenominationModal?: (product: Product) => void;
}) {
  const { openModal } = useModal()!;

  return (
    <Switch>
      <Match when={props.result.length > 0 && props.isSearchActive()}>
        <div class="mt-1 flex-col">
          {props.result.map((product) => (
            <div
              class={`${props.isFetchingResults() ? "opacity-40" : ""} transition-opacity`}
            >
              <Switch>
                <Match when={props.isProcurment}>
                  <div
                    onClick={() => {
                      if (
                        product.voucherProduct.status == "ACTIVE" &&
                        props.openDenominationModal
                      ) {
                        props.openDenominationModal!(product);
                      }
                    }}
                  >
                    <SearchResultCard
                      imageUrl={product.voucherProduct.logoUrl}
                      name={product.voucherProduct.title}
                      brandKey={product.brandKey}
                      onBrandClick={props.onBrandClick}
                      discountPercentage={
                        product.voucherProduct.discountPercentage
                      }
                      status={product.voucherProduct.status}
                      isProcurment={props.isProcurment}
                      iconClass="h-10 w-10"
                      titleClass="text-mediumBold text-basePrimaryDark"
                      subtitleClass="text-f12"
                    />
                  </div>
                </Match>
                <Match when={true}>
                  <A
                    href={
                      product.voucherProduct.status == "ACTIVE"
                        ? `/buy/${product.brandKey}-gift-card`
                        : ""
                    }
                  >
                    <SearchResultCard
                      imageUrl={product.voucherProduct.logoUrl}
                      name={product.voucherProduct.title}
                      brandKey={product.brandKey}
                      onBrandClick={props.onBrandClick}
                      discountPercentage={
                        product.voucherProduct.discountPercentage
                      }
                      status={product.voucherProduct.status}
                      isProcurment={false}
                      iconClass="h-12 w-12"
                      titleClass="text-normal text-neutral-800 "
                      subtitleClass="text-bold text-green-700"
                    />
                  </A>
                </Match>
              </Switch>
            </div>
          ))}
        </div>
      </Match>
      <Match when={props.noResultsFound && props.isSearchActive()}>
        <div class="  mt-1  bg-white">
          <div class="my-3 flex h-full w-full flex-col items-center justify-center">
            <HubbleImage
              src="https://app.myhubble.money/static/uploads/no-results-found.svg"
              class="h-[80px] w-[80px]"
              alt="no results found"
            />

            <div class="mt-2  max-w-[250px] text-center text-[15px] text-normal text-textNormal">
              Please try another keyword for brand or category
            </div>
          </div>
        </div>
      </Match>
    </Switch>
  );
}

function SearchResultCard(props: {
  imageUrl: string;
  name: string;
  brandKey: string;
  onBrandClick: () => void;
  discountPercentage: number;
  status: VoucherProductStatus;
  isProcurment?: boolean;
  titleClass: string;
  subtitleClass: string;
  iconClass: string;
}) {
  const [showIcon, setShowIcon] = createSignal(false);
  return (
    <div
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      class={`group mx-3 flex cursor-pointer flex-row items-center border-b border-[#EDEEF1] py-2 ${props.isProcurment ? "gap-2" : "gap-3"}`}
      onClick={() => {
        props.onBrandClick();
        webEventManager.sendEvent(HubbleEvent.CLICK_SEARCH_RESULT, {
          brandKey: props.brandKey,
          brandName: props.name,
        });
      }}
    >
      <HubbleImage
        src={props.imageUrl}
        class={`${props.iconClass} rounded-full ${props.status == "INACTIVE" ? "opacity-50" : ""}`}
        alt={`${props.name} logo`}
        errorImage={placeholderCard}
      />
      <div class="flex-1 flex-col ">
        <div
          class={`${props.titleClass} ${props.status == "INACTIVE" ? "opacity-50" : ""}`}
        >
          {props.name}
        </div>
        <Show when={props.discountPercentage > 0}>
          <div class={props.subtitleClass}>{props.discountPercentage}% off</div>
        </Show>
        <Show when={props.status == "INACTIVE"}>
          <div class="text-f12Bold text-errorDark">Temporarily unavailable</div>
        </Show>
      </div>
      {showIcon() && (
        <div class="pointer-events-none inset-y-0 right-0 flex items-center ">
          <i class={"ph ph-arrow-right "}></i>
        </div>
      )}
    </div>
  );
}
