import { createSignal, For } from "solid-js";
import { Faq } from "~/components/faq";
import { FAQ } from "~/data/static_faqs/types";

type FaqsProps = {
  headerText?: string;
  faqs: FAQ[];
  showSerialNumber?: boolean;
};

export function Faqs(props: FaqsProps) {
  const [openFaqSerialNumber, setOpenFaqSerialNumber] = createSignal<number>();

  return (
    <div class="inline-flex h-fit w-full flex-col items-start justify-start gap-3">
      {props.headerText ? (
        <h2 class="font-['Inter'] text-h5 text-textDark dark:text-textWhite lg:text-h4">
          {props.headerText}
        </h2>
      ) : null}
      <For each={props.faqs}>
        {(faq, index) => (
          <Faq
            sNo={index() + 1}
            question={faq.question.replace(/^\d\.\s/, "")}
            answer={faq.answer}
            openFaqSerialNumber={openFaqSerialNumber}
            setOpenFaqSerialNumber={setOpenFaqSerialNumber}
            showSerialNumber={props.showSerialNumber}
          />
        )}
      </For>
    </div>
  );
}
