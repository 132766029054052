import { Accessor, Show } from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";

type FaqProps = {
  sNo: number;
  question: string;
  answer: string;
  openFaqSerialNumber: Accessor<number | undefined>;
  setOpenFaqSerialNumber: (sNo: number | undefined) => void;
  showSerialNumber?: boolean;
};

export function Faq(props: FaqProps) {
  const toggleIsOpen = () => {
    if (isOpen()) {
      props.setOpenFaqSerialNumber(undefined);
    } else {
      props.setOpenFaqSerialNumber(props.sNo);
    }
  };

  function isOpen() {
    return props.openFaqSerialNumber() === props.sNo;
  }

  return (
    <div
      class="flex h-fit cursor-pointer flex-row items-start justify-start gap-1 self-stretch rounded-2xl border border-baseTertiaryDark bg-baseTertiaryLight p-4 text-basePrimaryDark shadow-base  dark:bg-[#222427] dark:text-textWhite "
      onClick={toggleIsOpen}
    >
      <Show when={props.showSerialNumber ?? true}>
        <span class="text-mediumBold lg:text-bold">{props.sNo}.</span>
      </Show>
      <div class="flex w-full flex-col justify-start transition-all duration-300">
        <div class="inline-flex flex-row justify-start gap-2 self-stretch">
          <h3 class="shrink grow basis-0 text-bold">{props.question} </h3>
          <div class="relative h-4 w-4 self-start justify-self-end">
            <PhosphorIcon name={isOpen() ? "minus" : "plus"} />
          </div>
        </div>
        <div
          class="flex  flex-col justify-start self-stretch overflow-hidden  text-medium  transition-all duration-200    "
          classList={{
            "max-h-[340px]": isOpen(),
            "max-h-[0px]": !isOpen(),
            "pt-3": isOpen(),
          }}
        >
          {props.answer}
        </div>
      </div>
    </div>
  );
}
