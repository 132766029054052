import {
  createEffect,
  createSignal,
  from,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { phonepeGpayPaytmLogo, upiLogoLongList } from "../../assets/assets";
import { makeUPIAppRequest } from "../../data/products";
import { ThreeDotLoader } from "../../widgets/button";
import { toRupees } from "../../utils/number";
import { getPaymentOrderDetails } from "~/server/apis/client_apis";
import { getOS, isMobile } from "~/utils/platform";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

export function PaymentQrCode(props: {
  orderId: string;
  amount: number;
  brandImageUrl: string;
  onClose: () => void;
  reInit: () => void;
}) {
  const [timeLeft, setTimeLeft] = createSignal<number>(-1);
  const [link, setLink] = createSignal<string>("");
  makeUPIAppRequest(props.orderId, "PAYTM").then(async (order) => {
    setLink(order.link);

    if (typeof window !== "undefined") {
      import("qr-code-styling").then((QRCode) => {
        const qrCode = new QRCode.default({
          width: 260,
          height: 260,
          data: link(),
          margin: 0,
          qrOptions: {
            mode: "Byte",
            errorCorrectionLevel: "Q",
          },
          imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
          dotsOptions: { type: "dots", color: "#000000" },
          backgroundOptions: { color: "#fffafa" },
          image: "https://assets.myhubble.money/web-assets/buying_text.png",
          cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
          cornersDotOptions: { color: "#000000" },
        });
        qrCode.append(document.getElementById("canvas") ?? undefined);
      });
    }
  });

  var timer: string | number | NodeJS.Timeout | undefined;

  onCleanup(() => {
    clearInterval(timer);
  });

  const getOrderDetail = async (orderId: string) => {
    const expiresAt = (await getPaymentOrderDetails(orderId)).expiresAt;
    if (expiresAt === null) return;
    const expiresAtLocalTime =
      new Date(expiresAt).getTime() +
      Math.abs(new Date(expiresAt).getTimezoneOffset() * 60 * 1000);
    const timeLeftToExpire = Math.floor(
      (expiresAtLocalTime - new Date().getTime()) / 1000
    );
    setTimeLeft(timeLeftToExpire);
    timer = setInterval(() => {
      if (timeLeft() <= 0) {
        setTimeLeft(0);
        clearInterval(timer);
        return;
      }
      setTimeLeft(timeLeft() - 1);
    }, 1000);
  };

  const [minutes, setMinutes] = createSignal(0);
  const [seconds, setSeconds] = createSignal(0);

  createEffect(() => {
    setMinutes(Math.floor(timeLeft() / 60));
    setSeconds(timeLeft() % 60);
  });

  onMount(async () => {
    if (!isMobile()) {
      await getOrderDetail(props.orderId);
    }
  });

  const QRData = (
    <div class="flex flex-col items-center justify-start">
      <div class="flex flex-col items-center justify-start pt-4">
        <div class="inline-flex h-[260px] w-[260px] items-center justify-center rounded-xl border  border-neutral-200 shadow">
          <Show when={link() !== ""} fallback={<ThreeDotLoader color="#000" />}>
            <div id="canvas"></div>
          </Show>
        </div>
        <img class="mt-3 h-[23px] w-[313px]" src={upiLogoLongList} />
      </div>
      <div class="text-medium leading-[18.20px] text-baseSecondaryMedium">
        .... or any UPI app{" "}
      </div>
      <Show when={timeLeft() > 0}>
        <div class="mb-4 mt-3 text-bold leading-[18.20px] text-neutral-700">
          <Show when={link() !== ""}>
            {minutes() < 10 ? "0" : ""}
            {minutes()}:{seconds() < 10 ? "0" : ""}
            {seconds()}
          </Show>
        </div>
      </Show>
    </div>
  );

  const tryAgain = (
    <div class="flex flex-col items-center justify-start">
      <div class="flex h-[400px]  w-full flex-col content-center items-center justify-center ">
        <PhosphorIcon
          name="warning-circle"
          size="fill"
          fontSize={32}
          class=" content-center text-errorDark"
        />
        <div class="mt-3 w-full content-center text-h3 text-errorDark">
          QR code expired
        </div>
        <div
          class="mt-1 cursor-pointer content-center text-f12Bold text-textDark"
          style={{ "text-decoration": "underline" }}
          onClick={() => {
            props.onClose();
            //pop animation takes 250ms
            setTimeout(() => {
              props.reInit();
            }, 300);
          }}
        >
          TRY AGAIN
        </div>
      </div>
    </div>
  );

  return (
    <div class=" inline-flex h-[600px]  gap-2.5 rounded-3xl border border-neutral-200 bg-white p-2">
      <div class=" relative inline-flex w-[486px] flex-col items-center justify-start   rounded-2xl border border-gray-100 bg-gradient-to-b from-[#dfedf1] via-white to-white py-6	">
        <div class="flex flex-col items-center justify-start  px-6">
          <div class="flex flex-col items-center justify-start ">
            <div class="text-h3 ">{toRupees(props.amount)}</div>
            <div class="text-center text-bold leading-relaxed">
              Scan & pay via any UPI app
            </div>
          </div>
          <Switch>
            <Match when={timeLeft() === 0}>{tryAgain}</Match>
            <Match when={true}>{QRData}</Match>
          </Switch>
        </div>
        <div class="absolute bottom-4 flex w-full flex-col items-center">
          <Divider bgColor="#EDEEF1" />
          <div class="mt-4 inline-flex flex-col items-center justify-start self-stretch">
            <div class="shrink grow basis-0 text-medium leading-[18.20px] text-baseSecondaryMedium">
              Note: You’ll be redirected to another page once payment is
              processed
            </div>
          </div>
        </div>

        <div
          class="absolute right-2 top-2 inline-flex h-6 w-6 cursor-pointer items-center justify-center gap-2.5 rounded-3xl bg-white p-5"
          onClick={props.onClose}
        >
          <div class="ph ph-x flex h-4 w-4 items-center justify-center p-[5px]"></div>
        </div>
      </div>
    </div>
  );
}
