import { Env } from "~/types";

export const HUBBLE_CLIENT_ID = "hubble";

type PartnerSecrets = {
  [key: string]: {
    clientId: string;
    clientSecret: string;
    clientLogoUrl: string;
  };
};

const STAGING_PARTNER_SECRETS: PartnerSecrets = {
  acme: {
    clientId: "acme-3241c2c1",
    clientSecret: "xhc0n8148d6a",
    clientLogoUrl:
      "https://gullak-assets.s3.ap-south-1.amazonaws.com/brand-images/plain-logos/acme.jpg",
  },
  payu: {
    clientId: "payu-3251d2e1",
    clientSecret: "983P4ybHE5SY",
    clientLogoUrl: "https://assets.myhubble.money/web-assets/PayU-logo.png",
  },
  bajaj: {
    clientId: "bajaj-3hdhbvbj",
    clientSecret: "sbdhbsjbcbdj",
    clientLogoUrl: "",
  },
};

const PROD_PARTNER_SECRETS: PartnerSecrets = {
  demo: {
    clientId: "demo-hubble-c5m2Dvjd",
    clientSecret: "6weX5C4NyE66",
    clientLogoUrl:
      "https://gullak-assets.s3.ap-south-1.amazonaws.com/brand-images/plain-logos/acme.jpg",
  },
  "in-entertainment": {
    clientId: "in-entertainment-JiscWpZH",
    clientSecret:
      "9AutWphyJLqhluGtFLUJD2XKQwkcWYzs29XziY8oLv0FlBXXoMMfkFvFUDbDY2qy",
    clientLogoUrl:
      "https://www.hindujagroup.com/resources/images/inel/inel-logo.jpg",
  },
  hafeleindia: {
    clientId: "hafeleindia-13140790",
    clientSecret: "263LwOqQuleu",
    clientLogoUrl:
      "https://gullak-assets.s3.ap-south-1.amazonaws.com/brand-images/plain-logos/image.png",
  },
  aubank: {
    clientId: "aubank-LEA6zSey",
    clientSecret:
      "sb7W1KPIvWK3geSeguQvly4cbO68H181FFAxAPtpo5sfrhHeYZrl9ecGSsreFWzR",
    clientLogoUrl:
      "https://assets.myhubble.money/web-assets/corp-gifting/au-brank-logo.png",
  },
  excellentpublicity: {
    clientId: "excellent-publicity-iOpO9ETL",
    clientSecret:
      "0aeqbeQvX6CzwRvJkW9TxrXltXmsKhpucfiQ1YS7r5F64miPCJw2aJzwJPDjzsF5",
    clientLogoUrl:
      "https://assets.myhubble.money/web-assets/corp-gifting/excellent-publicity-logo.webp",
  },
  avretail: {
    clientId: "avretail-zObdFy3Z",
    clientSecret:
      "MTrtD6loR9FUixTH8SyBJv4CLeiR2HzZ23Wqy7tjObwqjqXqFtIrsIUXlDkmjOHP",
    clientLogoUrl:
      "https://assets.myhubble.money/web-assets/corp-gifting/avretail-logo.png",
  },
};

export const getPartnerSecrets = (env: Env): PartnerSecrets => {
  if (env === "Prod" || env === "Preprod") {
    return PROD_PARTNER_SECRETS;
  }

  return STAGING_PARTNER_SECRETS;
};

export const getPartnerConfigWithClientId = (clientId: string, env: Env) => {
  const partnerData = getPartnerSecrets(env);

  for (const [key, value] of Object.entries(partnerData)) {
    if (value.clientId === clientId) {
      return value;
    }
  }

  return null;
};
