import { ThreeDotLoader } from "./button";

type DottedLoaderProps = {
  color?: string;
};

export function DottedLoader(props: DottedLoaderProps) {
  return (
    <div class="left-0 top-0 z-20 flex h-screen w-full items-center justify-center">
      <ThreeDotLoader color={props.color ?? "#000"} />
    </div>
  );
}
