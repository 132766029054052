import { debounce } from "@solid-primitives/scheduled";
import {
  Accessor,
  Match,
  Setter,
  Show,
  Switch,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";

export function SearchBar(props: {
  onInput: (e: Event) => void;
  onClear: () => void;
  searchQuery: Accessor<string>;
  setSearchQuery: Setter<string>;
  isSearchActive: Accessor<boolean>;
  setIsSearchActive: Setter<boolean>;
  isProcurment?: boolean;
}) {
  function onClear() {
    props.onClear();
    props.setIsSearchActive(true);
    setShowSearchBar(true);
    setOnClearCalled(true);
    searchInput?.focus();
  }

  const [showSearchBar, setShowSearchBar] = createSignal(false);
  const [onClearCalled, setOnClearCalled] = createSignal(false);

  const inputDebounce = debounce((e: Event) => {
    const inputElement = e.target as HTMLInputElement;
    props.onInput(e);
  }, 300);

  let searchInput: HTMLInputElement | undefined;
  createEffect(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    if (props.isSearchActive()) {
      setShowSearchBar(true);
      searchInput?.focus();
    }
  });

  return (
    <div>
      <Show when={props.isProcurment} fallback={searchBar()}>
        {procurementSearchBar()}
      </Show>
    </div>
  );

  function procurementSearchBar() {
    return (
      <div
        class={`flex w-[300px] items-center justify-start rounded-[8px] bg-baseTertiaryMedium px-3 py-2 ${props.isSearchActive() ? "border-[1px] border-[#313538]" : ""}`}
        onClick={() => {
          setShowSearchBar(true);
          searchInput?.focus();
        }}
      >
        <PhosphorIcon
          name="magnifying-glass"
          fontSize={16.0}
          class="pr-1 text-textNormal"
        />
        <Show
          when={showSearchBar()}
          fallback={<div class="text-medium text-textNormal">Search</div>}
        >
          {searchInputfield({
            class:
              "w-full border-none text-textDark text-mediumBold bg-baseTertiaryMedium  outline-none placeholder:text-medium placeholder:text-textNormal",
            placeholder: "Search",
            autofocus: false,
          })}
        </Show>

        <Show when={props.searchQuery().length > 0}>
          <div onClick={onClear} class="h-4 cursor-pointer">
            <PhosphorIcon name="x" fontSize={16.0} class="align-text-top" />
          </div>
        </Show>
      </div>
    );
  }

  function searchBar() {
    return (
      <div
        class=" flex h-[45px] flex-row items-center justify-center rounded-xl border border-slate-300 px-5 py-3 text-gray-900"
        style={{ "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)" }}
        onClick={() => {
          setShowSearchBar(true);
          searchInput?.focus();
        }}
      >
        <PhosphorIcon
          name="magnifying-glass"
          fontSize={20.0}
          class="text-textNormal"
        />
        <Switch>
          <Match when={showSearchBar()}>
            {searchInputfield({
              class:
                "ml-3 w-full border-none bg-white text-f16Bold text-basePrimaryDark  outline-none placeholder:font-normal placeholder:text-textNormal",
              placeholder: "Search for brands or categories",
              autofocus: true,
            })}
          </Match>
          <Match when={true}>
            <div class=" ml-3 w-full text-normal  text-textNormal sm:w-auto">
              Search for brands or categories
            </div>
          </Match>
        </Switch>

        <Show when={props.searchQuery().length > 0}>
          <button onClick={onClear}>
            <PhosphorIcon name="x" fontSize={20.0} class="align-text-bottom" />
          </button>
        </Show>
      </div>
    );
  }

  function searchInputfield(searchInputfieldProps: {
    class: string;
    placeholder: string;
    autofocus: boolean;
  }) {
    return (
      <input
        ref={searchInput}
        class={searchInputfieldProps.class}
        placeholder={searchInputfieldProps.placeholder}
        required
        onInput={inputDebounce}
        onFocus={(e) => {
          if (!props.isSearchActive()) {
            props.setIsSearchActive(true);
          }
        }}
        value={props.searchQuery()}
        autocomplete="off"
        autofocus={searchInputfieldProps.autofocus}
        onblur={async (e) => {
          await new Promise((resolve) => setTimeout(resolve, 250));
          if (onClearCalled()) {
            setOnClearCalled(false);
          } else {
            props.setIsSearchActive(false);
            if (props.searchQuery().length == 0) {
              setShowSearchBar(false);
            }
          }
        }}
      />
    );
  }
}
